import React from "react";
import classes from "./Statute.module.css";
import Button from "../../Components/Button";

const Statute = () => {
    return <div className={classes.text}>
    <p >
 Polityka Prywatności

Właścicielem strony internetowej ZSEMBook jest firma Zespół Szkół Elektryczno-Mechanicznych, która jest administratorem Twoich danych osobowych.

Niniejsza Polityka prywatności określa, w jaki sposób przetwarzamy informacje gromadzone na stronie ZSEMBook, a także wyjaśnia powody, dla których musimy gromadzić określone dane o użytkowniku. Dlatego przed rozpoczęciem korzystania ze strony ZSEMBook zapoznaj się z niniejszą Polityką Prywatności.

Dbamy o Twoje dane osobowe i zobowiązujemy się do zapewnienia ich poufności i ochrony.
Gromadzone przez nas dane osobowe:

Kiedy wchodzisz na stronę ZSEMBook, automatycznie zbieramy pewne informacje o Twoim urządzeniu, w tym informacje o Twojej przeglądarce internetowej, adresie IP, strefie czasowej oraz niektórych plikach cookie zainstalowanych na Twoim urządzeniu. Ponadto podczas przeglądania strony zbieramy informacje o poszczególnych stronach internetowych lub produktach, które przeglądasz, jakie strony lub frazy wprowadzone w wyszukiwarce skierowały Cię na naszą Stronę oraz o tym, w jaki sposób z niej korzystasz. Te informacje zbierane automatycznie określamy mianem "Informacji o urządzeniu". Ponadto, możemy gromadzić dane osobowe, które nam przekażesz (w tym, między innymi, imię, nazwisko, adres, informacje o płatnościach itp.) na etapie rejestracji, aby umożliwić realizację umowy.
Dlaczego przetwarzamy Twoje dane?

Naszym nadrzędnym priorytetem jest zapewnienie bezpieczeństwa danych klientów / użytkowników, dlatego też możemy przetwarzać jedynie ograniczone dane, tylko w takim zakresie, w jakim jest to absolutnie konieczne do utrzymania funkcjonowania Strony. Informacje zbierane automatycznie są wykorzystywane wyłącznie do identyfikacji potencjalnych przypadków nadużyć oraz do sporządzania danych statystycznych dotyczących korzystania z naszej Strony internetowej. Wspomniane dane statystyczne nie są gromadzone w sposób umożliwiający identyfikację konkretnego użytkownika systemu.

Możesz odwiedzić naszą Stronę internetową bez konieczności ujawniania nam swojej tożsamości lub przekazywania jakichkolwiek informacji, na podstawie których ktoś mógłby rozpoznać Cię jako konkretną, możliwą do zidentyfikowania osobę. Jeśli jednak zechcesz skorzystać z niektórych funkcji Strony, lub chcesz otrzymywać nasz newsletter, lub podać inne informacje poprzez wypełnienie formularza, możesz przekazać nam swoje dane osobowe, takie jak adres e-mail, imię, nazwisko, miasto w którym mieszkasz, firma, organizacja, numer telefonu. Możesz zrezygnować z podawania nam swoich danych osobowych, jednak wówczas możesz nie być w stanie korzystać z niektórych funkcji Strony. Na przykład, nie będziemy w stanie wysyłać Ci naszego Newslettera lub nawiązać z Tobą kontaktu bezpośrednio na Stronie. Użytkownicy, którzy nie są pewni, które informacje są obowiązkowe, mogą skontaktować się z nami pisząc na adres rekrutacja@zsem.edu.pl.
Twoje prawa:

Jeśli jesteś obywatelem Unii Europejskiej, przysługują Ci następujące prawa związane z Twoimi danymi osobowymi:

    Prawo do uzyskania informacji.
    Prawo dostępu do informacji.
    Prawo do korekty danych.
    Prawo do usunięcia danych.
    Prawo do ograniczenia przetwarzania danych.
    Prawo do zastrzeżenia sposobu przetwarzania danych.
    Prawo sprzeciwu.
    Prawa odnoszące się do automatyzacji podejmowania decyzji i profilowania.

Jeśli chcesz skorzystać z tego prawa, skontaktuj się z nami za pośrednictwem poniższych danych kontaktowych.

Ponadto, jeśli jesteś obywatelem Unii Europejskiej, zaznaczamy, że dane użytkownika mogą być przetwarzane w celu realizacji ewentualnych umów z nim zawartych (np. w przypadku złożenia zamówienia za pośrednictwem Strony) lub w celu realizacji naszych uzasadnionych działań biznesowych wymienionych powyżej. Ponadto należy mieć na uwadze, że informacje o użytkowniku mogą być przekazywane poza granice Unii Europejskiej, w tym do Kanady i Stanów Zjednoczonych.
Linki do innych stron

Na naszej Stronie internetowej mogą znajdować się linki do innych witryn, które nie stanowią naszej własności ani nie są przez nas nadzorowane. Informujemy, że nie ponosimy odpowiedzialności za inne strony internetowe ani za ich praktyki związane z ochroną prywatności. Z tego powodu zachęcamy do świadomego opuszczenia naszej strony i zapoznania się z polityką prywatności każdej strony internetowej, która może gromadzić dane osobowe.
Ochrona danych:

Zapewniamy bezpieczeństwo informacji udostępnianych przez użytkownika na serwerach komputerowych w środowisku kontrolowanym, bezpiecznym, zabezpieczonym przed nieuprawnionym dostępem, wykorzystaniem lub ujawnieniem. W ramach kontroli i nadzoru stosujemy odpowiednie zabezpieczenia administracyjne, techniczne i fizyczne w celu ochrony przed nieuprawnionym dostępem, wykorzystaniem, modyfikacją i ujawnieniem danych osobowych. Nie możemy jednak zagwarantować bezpieczeństwa transmisji danych przez sieć internetową lub bezprzewodową.
Ujawnienie informacji wynikających z przepisów prawa:

Wszelkie informacje, które gromadzimy, wykorzystujemy lub otrzymujemy, zostaną ujawnione, jeśli jest to wymagane lub zgodne z prawem, np. w celu wywiązania się z wezwania do sądu lub podobnego postępowania prawnego oraz wówczas, gdy w dobrej wierze uznamy, że ujawnienie jest koniecznie w celu ochrony naszych praw, Twojego bezpieczeństwa lub bezpieczeństwa innych osób, wykrycia oszustwa lub w odpowiedzi na wezwanie organów rządowych.
Informacje kontaktowe:

Jeżeli chcesz się z nami skontaktować, aby uzyskać więcej informacji na temat niniejszej Polityki Prywatności lub w jakiejkolwiek sprawie mającej związek z Twoimi prawami i danymi osobowymi, możesz wysłać wiadomość e-mail na adres rekrutacja@zsem.edu.pl. 
        </p>
        <Button buttonText='Powrót' destination='/auth/signup'/>
    </div>
}

export default Statute;